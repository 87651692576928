<template>
  <div class="usercenter">
    <van-nav-bar title="个人中心" left-arrow />
    <div class="usertop">
        <div class="photo"><img :src="userPhoto" alt=""></div>
        <div class="info">
           <div class="name"><span>{{ userName }}</span>  <van-button round  size="mini" type="default">成为团长</van-button> </div>
           <p class="time">加入时间：{{ jionTime }}</p>
        </div>
    </div>
    <div class="usermenu">
        <!-- 用户中心导航 -->
        <menu-bar />
    </div>
    <!-- 用户中心订单列表 -->
    <div class="orderlist">
         <order-card v-for="item in orderlist" :key="item.id" v-bind="item" ></order-card>

    </div>
     <!-- 底部导航 -->
     <bottom-bar />

  </div>
</template>

<script>
// @ is an alias to /src
import BottomBar from "@/components/BottomBar.vue";
import MenuBar from "@/components/MenuBar.vue";
import OrderCard from "@/components/OrderCard.vue";


export default {
  name: "usercenter",
  data() {
    return {
       userPhoto:require("@/assets/defaultuserpic.png"),
       userName:"哆啦A梦",
       jionTime:"2020-12-20 20:30",
        orderlist:[
            {
              id:"od1",
              num:5,
              price:30.5,
              desc:"描述描述",
              title:"标题标题标题",
              thumb:"/images/product1.jpg",
              time:"2020-12-12 15:30",
              state:"已支付",
              payInfo:"￥ 36.00"
          },
          {
              id:"od1",
              num:5,
              price:30.5,
              desc:"描述描述",
              title:"标题标题标题",
              thumb:"/images/product2.jpg",
              time:"2020-12-12 15:30",
              state:"已支付",
              payInfo:"￥ 36.00",
              isObjects:true,
          },
        ]


    }
  },
   methods: {

  },
  components: {
    'bottom-bar':BottomBar,
    'menu-bar':MenuBar,
    'order-card':OrderCard
  },
};
</script>
<style lang="scss">
.usercenter{
  .usertop{padding:20px;display:flex;justify-content: flex-start;color:#fff;background:#D50606;
      .photo{margin-right:10px;width:60px;height:60px;border-radius: 50%;border:2px solid #fff;overflow: hidden;}
      .info{
         .name{font-size:16px;padding:8px 0;display:flex;align-items: center;
           .van-button{margin-left:10px;}
         }
         .time{font-size: 12px;}
      }
  }
 .van-hairline--top-bottom::after{border:0;}
 .usermenu{
    width: 100%;content:"";display:block;margin-bottom: 35px;
    height: 50px;
    background:#D50606;
    border-radius: 0px 0px 100px 100px;
 }

}
</style>
